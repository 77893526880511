/*
 * @Author: zhengda.lv
 * @Date: 2022-01-13 09:52:03
 * @LastEditTime: 2022-01-18 11:24:34
 * @LastEditors: zhengda.lv
 * @Description:
 */
import { ref, computed, Ref, nextTick, onMounted } from 'vue';
import { UndefinedString } from '@/views/LocalRegistrationReport/types';

const useTable = (
    year = '2021',
    isVAN: Ref<boolean>,
    domId: string,
    type: string
): any => {
    //? year 是必选？默认值？
    const dataViewMap = {
        PROVINCE: ['provinceCn', 'provinceEn', 'region', 'subRegion'],
        CITY: ['cityCn', 'cityEn', 'region', 'subRegion', 'restrictedCity'], //TODO 顺序不同？
        REGION: ['region'],
        SUBREGION: ['subRegion'],
        MAKE: ['make'],
        DEALER_GROUP: ['dealerGroupCn', 'dealerGroupEn'],
        TYPE_CLASS: ['model', 'ifCriticalModel'],
    };
    const dynamicColumns = [
        {
            key: 'no',
            title: 'No',
            dataIndex: 'no',
            width: 50,
        },
        {
            key: 'dealerCode',
            title: 'Dealer Code',
            dataIndex: 'dealerCode',
            width: 200,
        },
        {
            key: 'dealerNameCn',
            title: 'Dealer Name(CN)',
            dataIndex: 'dealerNameCn',
            width: 200,
        },
        {
            key: 'dealerNameEn',
            title: 'Dealer Name(EN)',
            dataIndex: 'dealerNameEn',
            width: 200,
        },
        {
            key: 'restrictedCity',
            title: 'Curve Selection of Restricted Cities',
            dataIndex: 'restrictedCity',
            width: 250,
        },
        {
            key: 'dealerGroupCn',
            title: 'Dealer Group(CN)',
            dataIndex: 'dealerGroupCn',
            width: 200,
        },
        {
            key: 'dealerGroupEn',
            title: 'Dealer Group(EN)',
            dataIndex: 'dealerGroupEn',
            width: 200,
        },
        {
            key: 'region',
            title: 'Region',
            dataIndex: 'region',
            width: 200,
        },
        {
            key: 'subRegion',
            title: 'Sub Region',
            dataIndex: 'subRegion',
            width: 200,
        },
        {
            key: 'make',
            title: 'Make',
            dataIndex: 'make',
            width: 200,
        },
        {
            key: 'provinceCn',
            title: 'Province(CN)',
            dataIndex: 'provinceCn',
            width: 200,
        },
        {
            key: 'provinceEn',
            title: 'Province(EN)',
            dataIndex: 'provinceEn',
            width: 200,
        },
        {
            key: 'cityCn',
            title: 'City(CN)',
            dataIndex: 'cityCn',
            width: 200,
        },
        {
            key: 'cityEn',
            title: 'City(EN)',
            dataIndex: 'cityEn',
            width: 200,
        },
        {
            key: 'model',
            title: 'Model',
            dataIndex: 'typeClass',
            width: 200,
        },
        {
            key: 'ifCriticalModel',
            title: 'If Critical Model',
            dataIndex: 'ifCriticalModel',
            width: 200,
        },
    ];
    const generateFixedColumns = () => {
        const sonColumns = [
            { index: 'january', title: 'Jan' },
            { index: 'february', title: 'Feb' },
            { index: 'march', title: 'Mar' },
            { index: 'april', title: 'Apr' },
            { index: 'may', title: 'May' },
            { index: 'june', title: 'Jun' },
            { index: 'july', title: 'Jul' },
            { index: 'august', title: 'Aug' },
            { index: 'september', title: 'Sep' },
            { index: 'october', title: 'Oct' },
            { index: 'november', title: 'Nov' },
            { index: 'december', title: 'Dec' },
            { index: 'ytd', title: 'YTD' },
        ];
        const fatherColumns = {
            central: [
                {
                    title: `${year} Local Registration / RT %`,
                    index: 'localAndRtPercent',
                },
                {
                    title: `${year} Local Registration / (RT & Reg) %`,
                    index: 'localAndRegPercent',
                },
                {
                    title: `${year} RT Vol.`,
                    index: 'rtVol',
                },
                {
                    title: `${year} RT and Registration Vol.`,
                    index: 'rtRegVol',
                },
                {
                    title: `${year} RT and Local Registration Vol.`,
                    index: 'rtRegLocalVol',
                },
            ],
            region: [
                {
                    title: `${year} Local Registration / (RT & Reg) %`,
                    index: 'localAndRegPercent',
                },
            ],
        };

        return fatherColumns[type].reduce((cols: any, fatherCol: any) => {
            const fatherIndex = fatherCol.index;
            const children = sonColumns.map((sonCol: any) => {
                return {
                    title: sonCol.title,
                    dataIndex: `${fatherIndex}_${sonCol.index}`,
                    width: 100,
                };
            });
            return [...cols, { ...fatherCol, children }];
        }, []);
    };
    const fixedColumns = generateFixedColumns();

    const columns = ref<any>([]);
    const genAllColumns = (dataView: UndefinedString) => {
        if (isVAN.value) {
            dynamicColumns.splice(2, 0, {
                key: 'dealerCodeSWT',
                title: 'Dealer Code(SWT)',
                dataIndex: 'dealerCodeSWT',
                width: 200,
            });
        }
        if (!dataView) {
            columns.value = [
                ...dynamicColumns.slice(0, dynamicColumns.length - 2),
                ...fixedColumns,
            ];
        } else {
            const selectedColNames = ['no', ...dataViewMap[dataView]];
            console.log('dataView', dataView);
            console.log('selectedColNames', selectedColNames);
            const selectedCols = dynamicColumns.filter((col: any) => {
                return selectedColNames.includes(col.key);
            });
            columns.value = [...selectedCols, ...fixedColumns];
        }
    };
    const tableWidth = computed(() => {
        const width = columns.value.reduce((sum: number, col: any) => {
            if ('children' in col) {
                const childrenWidth = col.children.reduce(
                    (sum2: number, col2: any) => {
                        return sum2 + col2.width;
                    },
                    0
                );
                return sum + childrenWidth;
            }
            return sum + col.width;
        }, 0);
        return width + 10;
    });

    const colHeight = ref(500);
    const calculateHeight = (domId: string) => {
        const table = document.getElementById(domId);
        const clinetHeight = document.body.clientHeight;
        if (table) {
            const top = table.getBoundingClientRect().top;
            colHeight.value = clinetHeight - top - 90;
        }
    };

    nextTick(() => {
        calculateHeight(domId);
    });

    onMounted(() => {
        window.addEventListener('resize', () => calculateHeight(domId));
    });
    return { columns, genAllColumns, tableWidth, colHeight };
};

export default useTable;
